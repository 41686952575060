import './App.css';
import './index.css';
import video from './video/video.mp4'
import logo from './images/logo.png'
import Main from './utils/main';

function App() {
  return (
    <div className=" bg-black">
      <video src={video} autoPlay muted loop playsInline  preload="auto" className="bgvideo"/>
      <img src={logo} className="text-center mx-auto md:w-1/3 w-full top-10 z-20 absolute inset-x-0 md:top-1/3" alt="Uniwave" />
      <div className="hidden md:block">
        <div className="right-0 text-white absolute z-20 top-0 fixed mt-12 mr-12 text-sm  md:text-lg">
           The NFT Curated Platform
        </div>
        <div className="right-0 text-white absolute z-20 top-0 fixed mt-20 mr-12 text-sm md:text-lg font">
          Coming Soon
        </div> 
      </div>
      <Main />
    </div>
  );
}

export default App;
