import roadmap from "../images/roadmap.webp"
import {AnimatedOnScroll} from "react-animated-css-onscroll";

export default function Roadmap() {      

    return (

        
      <div className=" bg-gradient-to-b from-ocean_blue to-black">
      
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-gradient-to-b from-ocean_blue to-black">
        <h2 className="text-center text-4xl text-white mb-20 font-bold">Roadmap</h2>
        <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
          <div className="lg:py-6 lg:pr-16">
          <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={2000} isVisible={false}>
            
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-white"
                      stroke="currentColor"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-white" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold text-blue-400  ">Q1 2022</p>
                <div className="text-white flex flex-col">
                  <div>Seed the curating and artists nodes, beta version on the Testnet (Jan. 2022)</div>
                  <div>Official Launch of Uniwave on the Binance Smart Chain</div>
                  <div>Sale of the Privilege Uniwave NFT</div>
                  <div>Expansion of the network of curators and artists</div>
                </div>
              </div>
            </div>
            </AnimatedOnScroll>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={false}>
            
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-white"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold text-blue-400">Q2 2022</p>
                <p className="text-white flex flex-col">
                  <div>NFT exhibitions and live auctions in real locations</div>
                  <div>First cross-chain partnerships</div>
                  <div>Secondary Market expansion</div>
                </p>
              </div>
            </div>
            </AnimatedOnScroll>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={false}>
            
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-white"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold text-blue-400">Q3 2022</p>
                <div className="text-white flex flex-col">
                 <div>Launch of the seed and investment unit, Uniwave Labs</div>
                 <div>Launch of the Uniwave governance token + Airdrops</div>
                </div>
              </div>
            </div>
            </AnimatedOnScroll>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3500} animationOutDuration={3500} isVisible={false}>
            
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-white"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold text-blue-400 ">Q4 2022</p>
                <p className="text-white">
                Deployment of the metaverse, airdrops and auctions of land units
                </p>
              </div>
            </div>
            </AnimatedOnScroll>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={4000} animationOutDuration={4000} isVisible={false}>
            
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-6 text-white"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <polyline
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="6,12 10,16 18,8"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <p className="mb-2 text-lg font-bold text-blue-400">2023</p>
             
              </div>
            </div>
            </AnimatedOnScroll>
          </div>
          <div className="relative">
            <img
              className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full rounded-lg opacity-80"
              src={roadmap}
              alt="Digital art gallery"
            />
          </div>
        </div>
      </div>
      </div>
    );
  };