import {AnimatedOnScroll} from "react-animated-css-onscroll";
import {
  GlobeIcon,
  UserAddIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  ChevronDoubleUpIcon,
  SparklesIcon,
  UsersIcon,
  CubeTransparentIcon,
  AcademicCapIcon,
  PuzzleIcon,
  ArrowsExpandIcon,
} from '@heroicons/react/outline'

import site from '../images/site.webp'
import site2 from '../images/site2.webp'
import site3 from '../images/site3.webp'
import gallery from '../images/gallery.jpeg'
import spelar from '../images/spelar_logo.png';
import {ReactComponent as BinanceSVG} from '../images/binance.svg';
import {ReactComponent as TransakSVG} from '../images/transak.svg';
import ModelViewer from '../components/ModelViewer'
import Roadmap from '../components/Roadmap'
 
const features = [
  {
    id: 1,
    name: '3-Dimensional Metaverse',
    description: 'Empowering Artists, Curators, and Collectors. Borderless.',
    icon: GlobeIcon,
    time: 300,
  },
  {
    id: 2,
    name: 'Community Driven',
    description: 'Run by and for the Community with associated long-term benefits.',
    icon: UsersIcon,
    time: 400,
  },
  {
    id: 3,
    name: 'Innovative',
    description: 'Grow your NFT collection with Uniwave and foster innovative projects with Uniwave Labs. Be part of the future wave of NFTs',
    icon: CurrencyDollarIcon,
    time: 500,
  },
  {
    id: 4,
    name: 'Metaverse',
    description: 'Participate to the build of a new Metaverse dedicated to art.',
    icon: CubeTransparentIcon,
    time: 600,
  }
]
const metrics = [
  { id: 1, stat: '5 BNB', emphasis: 'Total BNB', rest: 'sold on the blockchain with Uniwave', time: 3000 },
  { id: 2, stat: '50+', emphasis: 'NFT minted', rest: 'in the blockchain and sended to their owner.', time: 4000 },
  { id: 3, stat: '100+', emphasis: 'Artists', rest: 'Registered on uniwave', time: 5000 },
  { id: 4, stat: '1', emphasis: 'Project incubed', rest: 'with Uniwave Labs', time: 6000 },
]
const footerNavigation = {
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      id: 1,
      name: 'Facebook',
      href: 'https://www.facebook.com/AppUniwave/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      id: 2,
      name: 'Instagram',
      href: 'https://www.instagram.com/uniwave_app/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      id: 3,
      name: 'Twitter',
      href: 'https://twitter.com/Uniwave_app',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      id: 4,
      name: 'Discord',
      href: 'https://discord.gg/GajMuEMyNc',
      icon: (props) => (
        <svg  fill="currentColor" width="24" height="24" viewBox="0 0 71 55"  xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0)">
        <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z"  fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="71" height="55" />
        </clipPath>
        </defs>
        </svg>
      ),
    },

  ],
}

export default function Main() {
  return (
    <div className="bg-black">
      <main>
        {/* Hero section */}
        <div className="relative">
         
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-black" />
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div className="absolute inset-0">
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 className="text-center text-6xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">Join the new </span>
                    <div className="neon">
                      <span className="text-metaverse" data-text="Metaverse">Metaverse</span>
                      <span className="gradient-metaverse"></span>
                      <span className="spotlight"></span>
                    </div>
                  </h1>
                  <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div className="space-y-0 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                      <a
                        href="https://discord.gg/GajMuEMyNc" target="_blank" rel="noreferrer"
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white sm:px-8"
                      >
                      <button className="glow-on-hover" type="button">JOIN DISCORD</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        {/* Logo Cloud */}
        <div className="bg-black mt-10">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm font-semibold uppercase text-white tracking-wide mb-8">
             They trust us
            </p>
            <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-3">
              <a href="https://binance.com" target="_blank" rel="noreferrer" aria-label={"Binance"}>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">  
                    <BinanceSVG className="h-12 text-color-900 opacity-10 hover:opacity-100 transition duration-500 "/>
                </div>
              </a>
              <a href="https://transak.com/" target="_blank" rel="noreferrer" aria-label={"Transak"}>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <TransakSVG className="h-14 text-color-900 opacity-10 hover:opacity-100 transition duration-500 "/>
                </div>
              </a> 
              <a href="https://spelar.io" target="_blank" rel="noreferrer" aria-label={"Spelar"}>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img alt="Spelar Hologramme Logo" src={spelar} className="text-color-900 opacity-10 hover:opacity-100 transition duration-500 "/>
                </div>
              </a>                 
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-20 pb-32 overflow-hidden">
        <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={false}>
          <div className=" text-transparent bg-clip-text bg-gradient-to-l from-ocean_blue to-blue-500 text-6xl font-bold text-center mb-20">Artists</div>
          </AnimatedOnScroll>
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 " />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <AnimatedOnScroll animationIn="fadeInLeft" animationOut="backInLeft" animationInDuration={500} animationOutDuration={500} isVisible={false}>
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                      <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-white">
                      Keep it Simple
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Easy interface, low minting cost, environment-friendly
                    </p>

                  </div>
                </div>
                </AnimatedOnScroll>

  


                <div className="mt-8 pt-6">
                 <div>
                  <AnimatedOnScroll animationIn="fadeInLeft" animationOut="backInLeft" animationInDuration={650} animationOutDuration={650} isVisible={false}>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                        <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-white">
                        Expand your Reach
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                        Interact with curators and collectors, join group exhibitions, organize your own solo projects
                        </p>
                       
                    </div>
                    </AnimatedOnScroll>
                  </div>
                </div>

                <div className="mt-8 pt-6">
                 <div>
                  <AnimatedOnScroll animationIn="bounceInLeft" animationOut="backInLeft" animationInDuration={750} animationOutDuration={750} isVisible={false}>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                        <PuzzleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-white">
                        Be part of a like-minded community
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                        Join an ambitious artist- and curator-run platform
                        </p>
                       
                    </div>
                    </AnimatedOnScroll>
                  </div>
                </div>


              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} isVisible={false}>
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={site}
                    alt="Inbox user interface"
                  />
                  </AnimatedOnScroll>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">  
          <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={false}>
          <div className=" text-transparent bg-clip-text bg-gradient-to-l from-ocean_blue to-blue-500 text-6xl font-bold text-center mb-20">Curators</div>
          </AnimatedOnScroll>
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                    <AnimatedOnScroll animationIn="fadeInRight" animationOut="backInRight" animationInDuration={500} animationOutDuration={500} isVisible={false}>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue to-ocean_blue_light">
                            <UserAddIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                         </div>
                        <h2 className="mt-6 text-3xl font-extrabold tracking-tight text-white">Bring your Skills</h2>
                        <p className="mt-4 text-lg text-gray-500">Organize solo or group NFT exhibitions with your own identity from a simple, customizable & powerful interface</p>
                    </AnimatedOnScroll>

                    <div className="mt-6">
                 <AnimatedOnScroll animationIn="fadeInRight" animationOut="backInRight" animationInDuration={650} animationOutDuration={650} isVisible={false}>
                  
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue to-ocean_blue_light">
                      <SparklesIcon  className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                    <h2 className="text-3xl font-extrabold tracking-tight text-white mt-6">
                      Generate Revenues
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Be part of the first decentralized curatorial blockchain, and get rewarded
                    </p>
                  </AnimatedOnScroll >
                  </div>


                  <div className="mt-6">
                     <AnimatedOnScroll animationIn="bounceInRight" animationOut="backInRight" animationInDuration={750} animationOutDuration={750} isVisible={false}>
                  
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue to-ocean_blue_light">
                      <CubeTransparentIcon  className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                    <h2 className="text-3xl font-extrabold tracking-tight text-white mt-6">
                    Shape the NFT metaverse
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      The age of gatekeepers is over, welcome to a new dimensional era.
                        Empower artists, create relevance, imagine the future
                    </p>
                  </AnimatedOnScroll >
                  </div>


                </div>  
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} isVisible={false}>
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={site2}
                    alt="Customer profile user interface"
                  />
                  </AnimatedOnScroll >
                </div>
              </div>
         
            </div>
          </div>
     
          <div className="relative pt-16 pb-32 overflow-hidden">
          <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={false}>
          <div className=" text-transparent bg-clip-text bg-gradient-to-l from-ocean_blue to-blue-500 text-6xl font-bold text-center mb-20">Collectors</div>
          </AnimatedOnScroll>
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 " />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <AnimatedOnScroll animationIn="fadeInLeft" animationOut="backInLeft" animationInDuration={500} animationOutDuration={500} isVisible={false}>
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                      <ChevronDoubleUpIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-white">
                      Invest with a Vision
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Curation brings benefits: invest with confidence in carefully selected unique NFT projects
                    </p>

                  </div>
                </div>
                </AnimatedOnScroll>
                <div className="mt-8 pt-6">
                  <AnimatedOnScroll animationIn="fadeInLeft" animationOut="backInLeft" animationInDuration={650} animationOutDuration={650} isVisible={false}>
                  <div>
                      <div>
                          <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                          <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                      </div>
                      <div className="mt-6">
                          <h2 className="text-3xl font-extrabold tracking-tight text-white">
                            Magnify Your Assets
                          </h2>
                          <p className="mt-4 text-lg text-gray-500">
                            Display and actively manage your collection on multiple channels
                          </p>

                      </div>
                    </div>
                    </AnimatedOnScroll>
                  </div>


                  <div className="mt-8 pt-6">
                  <AnimatedOnScroll animationIn="bounceInLeft" animationOut="backInLeft" animationInDuration={750} animationOutDuration={750} isVisible={false}>
                  <div>
                      <div>
                          <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-ocean_blue_light to-ocean_blue">
                          <ArrowsExpandIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                      </div>
                      <div className="mt-6">
                          <h2 className="text-3xl font-extrabold tracking-tight text-white">
                            Step into the Future
                          </h2>
                          <p className="mt-4 text-lg text-gray-500">
                            Be a pioneer of the new art market: seed innovative projects through our incubating unit Uniwave Labs
                          </p>

                      </div>
                    </div>
                    </AnimatedOnScroll>
                  </div>


              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} isVisible={false}>
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={site3}
                    alt="Inbox user interface"
                  />
                  </AnimatedOnScroll>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        
        <div className="ocean relative">
            <div className="wave"></div>
            <div className="wave"></div>
           
        </div>
        <div className="bg-ocean_blue">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" isVisible={false}>
              <h2 className="text-3xl font-extrabold text-white tracking-tight">Uniwave</h2>
              <p className="mt-4 max-w-3xl text-lg text-purple-200">
              Join a transformative NFT ecosystem, be part of the new Art Metaverse
              </p>
              </AnimatedOnScroll>
              <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.id}>
                   <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={800} isVisible={false}>
                      <div>
                        <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="mt-6">
                        <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                        <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                      </div>
                      </AnimatedOnScroll>
                    </div>
                ))}
              </div>
            </div>
        </div>

        {/*ModelViewer */}
       
        <Roadmap/>
        <ModelViewer/>
        {/* Stats section */}
        <div className="relative bg-black">
          <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
              <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={4000} animationOutDuration={4000} isVisible={false}>
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src={gallery}
                  alt="People working on laptops"
                />
                 </AnimatedOnScroll>
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-black xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
              <h2 className="text-sm font-semibold tracking-wide uppercase">
                <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-2xl text-transparent">
                 Metrics
                </span>
              </h2>
             
              <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                {metrics.map((item) => (
                  <div key={item.id}>
                   <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={item.time} animationOutDuration={item.time} isVisible={false}>
                      
                        <span className="block text-2xl font-bold text-ocean_blue">{item.stat}</span>
                        <span className="mt-1 block text-base text-white">
                          <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                        </span>
                        </AnimatedOnScroll>
                    </div>
                 
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-black">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-4xl">
              <span className="block">Ready to get started?</span>
              <span className="block bg-gradient-to-l from-ocean_blue to-indigo-600 bg-clip-text text-transparent">
                Get in touch or create an account.
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <a href="https://discord.gg/GajMuEMyNc" target="_blank" rel="noreferrer"
                className="flex items-center justify-center bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
              <button className="glow-on-hover" type="button">JOIN DISCORD</button>
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-black" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto pt-12 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
            <div className="flex space-x-6 md:order-2">
              {footerNavigation.social.map((item) => (
                <a key={item.id} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; 2021 Uniwave, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}