import object from '../images/3d/uniwave_color.glb'
import object_webp from '../images/3d/uniwave_color.webp' 
import {AnimatedOnScroll} from "react-animated-css-onscroll";

import {
    GlobeIcon,
    CubeTransparentIcon,
    SparklesIcon,
     BadgeCheckIcon
  } from '@heroicons/react/outline'

const featuresNftPrivilege = [
  {
    name: 'Get rewarded',
    icon: SparklesIcon,
    description:
    'You will be whitelisted for our future governance token IDO, Metaverse land auctions and benefit from our regular NFT airdrops and partnerships',
  },
  {
    name: 'Invitation',
    icon: GlobeIcon,
    description:
      'You will be invited to uniwave collector previews for sought-after exhibitions and NFT drops as well as attending shows irl',
  },
  {
    name: 'Badge',
    icon: BadgeCheckIcon,
    description:
      'Receive your badge on the Uniwave application, you will of course receive your NFT, some of them are rarer than others.',
  },
  {
    name: 'Metaverse',
    icon: CubeTransparentIcon,
    description:
      'Get your free land in our Metaverse dedicated to Art.',
  },
]


export default function ModelViewer() {
    return (
    <div>
    <div className="relative bg-black">
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-y-0 right-0 w-1/2 bg-black rounded-b-xl" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
        <div className="bg-black py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div className="max-w-lg mx-auto lg:mx-0">
          <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={2000} isVisible={false}>
         
            <h2 className="font-extrabold tracking-tight text-white sm:text-4xl mt-20 mb-5">
                <span className="block text-3xl">Want to be early ?</span>
                <span className="block text-2xl bg-gradient-to-l to-ocean_blue from-white bg-clip-text text-transparent">
                  Support the project by getting your NFT Privilege
                </span>
              </h2>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={false}>
         
            <dl className="mt-12 space-y-10">
              {featuresNftPrivilege.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute h-12 w-12 flex items-center justify-center bg-ocean_blue rounded-md">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-ocean_blue">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-300">{feature.description}</dd>
                </div>
              ))}
            </dl>
            </AnimatedOnScroll>
          </div>
        </div>
        <div className="bg-black py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end rounded-l-xl">
          <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
         
              <div className='w-9/12 h-96 mx-auto justify-center block flex text-center mb-12'> 
                <model-viewer
                    id="reveal"
                    className="mx-auto justify-center h-full w-full bg-black"
                    alt="Uniwave rotating logo in 3D" 
                    src={object} 
                    auto-rotate 
                    poster={object_webp} 
                    seamless-poster 
                    reveal={"auto"}
                    shadow-intensity="1" 
                    camera-controls
                    style={{with: "100%", height:"100%" , backgroundColor:"black"}}
                    >

                    </model-viewer>
                </div>
 

                <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut" animationInDuration={4000} animationOutDuration={4000} isVisible={false}>
            
                    <h2 className="sr-only">Price</h2>
                    <p className="relative">
                      <span className="flex flex-col text-center block text-2xl text-ocean_blue">
                        <span className="text-5xl font-extrabold tracking-tight">1 BNB</span>
                        <div className='text-white text-sm mt-2'>The Privilege NFT is limited to 1212 NFTs</div>
                      </span>
                    </p>
                  
                
                <button className="flex glow-on-hover text-center justify-center mx-auto p-3 mt-4" type="button">MINT SOON</button>
                </AnimatedOnScroll>
           </div>
        </div>
      </div>
    </div>
    </div>
    )
}
